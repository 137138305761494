/* Custom CSS for entire project */

:root {
    --background-color-light: #efefef;
    --text-color-light: #333333;
    --highlight-color-light: #e1e1e1;
    --hover-color-light: #cccccc;

    --background-color-dark: #333333;
    --text-color-dark: #ffffff;
    --highlight-color-dark: #44444b;
    --hover-color-dark: #56565d;
    --fill-color-dark: #282828;
}


/* LIGHT THEME COLORING*/

@media (prefers-color-scheme: light) {
    .app-container {
        margin: 0;
        background-color: white;
        color: var(--text-color-light);
    }

    a {
        color: var(--text-color-light);
    }

    a:hover {
        color: var(--hover-color-light);
    }

    .content-section {
        background-color: var(--background-color-light);
        box-shadow: 3px 0 7px -3px #3a3a3a, -3px 0 7px -3px #3a3a3a;
    }

    .navbar {
        background-color: var(--highlight-color-light);
        box-shadow: 3px 0 7px -3px #3a3a3a, -3px 0 7px -3px #3a3a3a;
    }

    .navbar-brand, .navbar-brand:hover, .navbar-brand:focus {
        color: var(--text-color-light);
    }

    .bars-icon {}
    color: var(--text-color-light);

    .bars-icon:hover {
        background-color: var(--hover-color-light);
    }

    .nav-link:hover {
        background-color: var(--hover-color-light);
    }

    .offcanvas {
        background-color: var(--highlight-color-light);
        color: var(--text-color-light);
    }

    .card {
        box-shadow: 0 0 3px #3a3a3a;
    }

    #footer {
        background-color: var(--highlight-color-light);
        box-shadow: 3px 0 7px -3px #3a3a3a, -3px 0 7px -3px #3a3a3a;
    }
}


/* DARK THEME COLORING */

@media (prefers-color-scheme: dark) {
    .app-container {
        margin: 0;
        background-color: var(--fill-color-dark);
        color: var(--text-color-dark);
    }

    .body-override {
        background-color: var(--fill-color-dark);
    }

    a {
        color: var(--text-color-dark);
    }

    a:hover {
        color: var(--hover-color-dark);
    }

    .content-section {
        background-color: var(--background-color-dark);
        color: var(--text-color-dark);
        box-shadow: 3px 0 7px -3px #000000, -3px 0 7px -3px #000000;
    }

    .navbar {
        background-color: var(--highlight-color-dark);
        box-shadow: 3px 0 7px -3px #000000, -3px 0 7px -3px #000000;
    }

    .navbar-brand, .navbar-brand:hover, .navbar-brand:focus {
        color: var(--text-color-dark);
    }

    .bars-icon {}
    color: var(--text-color-dark);

    .bars-icon:hover {
        background-color: var(--hover-color-dark);
    }

    .nav-link:hover {
        background-color: var(--hover-color-dark);
    }

    .offcanvas {
        background-color: var(--highlight-color-dark);
        color: var(--text-color-dark);
    }

    .form-control {
        background-color: var(--highlight-color-dark);
        color: var(--text-color-dark);
        border-color: black;
    }

    .form-control::placeholder {
        color: var(--text-color-dark);
    }

    .form-control:focus {
        background-color: var(--hover-color-dark);
        color: var(--text-color-dark);
        border-color: black;
    }

    .muted {
        color: var(--text-color-dark);
    }

    .btn {
        background-color: var(--highlight-color-dark);
        border-color: black;
    }

    .btn:hover {
        background-color: var(--hover-color-dark);
        border-color: black;
    }

    .btn:disabled {
        background-color: var(--highlight-color-dark);
        border-color: black;
    }

    .card {
        background-color: var(--highlight-color-dark);
        color: var(--text-color-dark);
        box-shadow: 0 0 3px #000000;
    }

    .link {
        background-color: var(--hover-color-dark);
    }

    .accordion-button {
        background-color: var(--highlight-color-dark);
        color: var(--text-color-dark);
        border-color: black;
    }

    .accordion-button:not(.collapsed) {
        background-color: var(--highlight-color-dark);
        color: var(--text-color-dark);
        border-color: black;
    }

    .accordion-body {
        background-color: var(--highlight-color-dark);
    }

    .list-group-item {
        background-color: var(--highlight-color-dark);
        color: var(--text-color-dark);
    }

    #footer {
        background-color: var(--highlight-color-dark);
        color: var(--text-color-dark);
        box-shadow: 3px 0 7px -3px #000000, -3px 0 7px -3px #000000;
    }
}


/* NAVBAR & OFF-CANVAS */

.navbar {
    padding: 10px;
}

.navbar-brand {
    font-size: 2em;
    font-weight: bold;
}

.nav-item {
    margin-right: 5px;
}

.nav-link {
    font-size: 1.5em;
    padding: 20px;
}

.nav-link:hover {
    border-radius: 5px 5px;
}

.accordion {
    margin-bottom: 25px;
}

.bars-icon {
    font-size: 1.5em;
}

.socials-icon {
    font-size: 2.5em;
    margin:10px;
}


/* CONTENT SECTIONS */

.content-section {
    text-align: center;
    font-size: 1.5em;
    padding: 50px;
}


/* FOOTER */

#footer {
    text-align: center;
    padding: 10px;
    font-size: 1.2em;
}
